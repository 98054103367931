export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 444;
export const CATEGORY_IMAGE_HEIGHT = 236;

export const BANNER_IMAGE_WIDTH = 1366;
export const BANNER_IMAGE_HEIGHT = 668;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 360;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 288;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 432;

export const TESTIMONIALS_IMAGE_WIDTH = 222;
export const TESTIMONIALS_IMAGE_HEIGHT = 302;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 80;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 80;

export const DEFAULT_LAYOUT_SELECTION = 13;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH6';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB5';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS1';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS4';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial9';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'capitalize font-semibold';
export const SLIDER_TOP_PADDING = 'pt-0';

export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
    showDiscountLabel: false,
  },
};
export const COMMON_TOAST_MESSAGE = {};
