import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-150 md:w-160 md:min-h-48 min-h-40 py-8 px-12 text-center  hover:opacity-80 text-14 md:text-16 font-bold text-black_variant_1 bg-yellow  focus:outline-none rounded-full disabled:opacity-20 max-w-160 flex justify-center items-center border border-transparent ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
