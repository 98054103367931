import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import bag from '@images/toastBag.svg';
import ToastCloseIcon from '@images/toastClose.svg';
// import classnames from 'classnames';
// import bag from '@images/toastBag.svg';
import { ReactComponent as ToastCloseWhiteIcon } from '@images/toastCloseWhite.svg';
import classNames from 'classnames';
import classnames from 'classnames';
// import ToastCloseIcon from '@images/toastClose.svg';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <div
      className={classnames({
        'z-50 w-full px-10 md:min-w-400 md:justify-center md:items-center fixed md:absolute mx-auto bottom-10 md:top-90': toasts[0].content.startsWith(
          'Added',
        ),
      })}
    >
      {toasts.map((toast) =>
        !toast.content.startsWith('Added') ? (
          <div
            key={toast.key}
            className={
              // toast.variant === 'bg-toast-error'
              'text-black p-10 first:mt-0 mt-10 bg-yellow'
              //  'bg-successToast bg-opacity-10 text-successToast m-10 border-successToast border-l-4 border-t-0 border-b-0 border-r-0'
            }
          >
            <div className="container md:pr-50 mx-auto flex items-center py-16 text-15 justify-between">
              <div className="flex md:w-full md:text-center justify-center" font-customisation="para-text">
                {/* <div className="font-bold mr-10 tracking-wider">
                {toast.variant === 'bg-toast-error' ? 'Error!' : 'Success!'}
              </div> */}
                <img src={bag} alt="bag" className="mr-14 md:mr-16" />

                <div className="text-couponText" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
              </div>
              <div onClick={() => removeToast(toast.key)}>
                <img src={ToastCloseIcon} alt="close" className="cursor-pointer" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              key={toast.key}
              className={classNames('shadow-toastShadow rounded-10 mt-10 min-h-60', {
                'bg-errorToast': toast.variant === 'bg-toast-error',
                'bg-successToast': toast.variant !== 'bg-toast-error',
              })}
            >
              <div className="flex w-full items-center p-20 text-14 font-semibold text-white  justify-between">
                <div font-customisation="para-text" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                <ToastCloseWhiteIcon
                  className="cursor-pointer min-w-12 min-h-12 ml-30 fill-white"
                  onClick={() => removeToast(toast.key)}
                />
              </div>
            </div>
          </div>
        ),
      )}
    </div>
  );
};

export default Toasts;
