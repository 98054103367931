import Email from '@common/images/Email.svg';
import Facebook from '@common/images/FacebookFoot.svg';
import Instagram from '@common/images/Instagram.svg';
import Linkedin from '@common/images/Linkedin.svg';
import Phone from '@common/images/Phone-call.svg';
import Pin from '@common/images/Pin.svg';
import Pinterest from '@common/images/Pinterest.svg';
import Twitter from '@common/images/TwitterFoot.svg';
import Youtube from '@common/images/Youtube.svg';
import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'text-center md:text-left md:w-full w-fit mx-auto md:mx-0 mb-14 md:mb-20 flex text-priceText text-16 leading-24 font-normal';
  const header =
    'text-center md:text-left text-priceText tracking-quickLinks uppercase leading-24 text-16 mb-30 font-bold';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div
      className="w-full"
      style={{
        backgroundColor: `#f6f6f6`,
      }}
      color-customisation="footer-bg"
      id="page-footer"
    >
      <div className="w-full container mx-auto pt-50 pb-20 md:pb-40">
        <div className="">
          <div className="block md:hidden py-12 ">
            <Link
              to="/"
              id="mobile-footer-logo"
              className="flex rounded justify-center md:justify-start mx-auto mb-32 overflow-hidden"
            >
              {pageHeaderLayout?.logo ? (
                <Image
                  noDefaultDimensions
                  src={pageHeaderLayout?.logo}
                  alt={storeInfo?.storename}
                  className="overflow-hidden object-contain h-44"
                />
              ) : (
                <div className="md:font-medium text-20 text-black ">{storeInfo?.storename}</div>
              )}
            </Link>
          </div>
          <div className=" md:justify-between justify-center">
            <div className="flex flex-wrap justify-between flex-col md:flex-row md:px-4">
              <div className="md:block hidden md:pr-20">
                <Link to="/" id="footer-logo">
                  {pageHeaderLayout?.logo ? (
                    <Image
                      noDefaultDimensions
                      src={pageHeaderLayout?.logo}
                      alt={storeInfo?.storename}
                      className=" h-44 rounded"
                    />
                  ) : (
                    <div className="font-medium text-30 text-black">{storeInfo?.storename}</div>
                  )}
                </Link>
              </div>
              <div className="mb-50 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Menu
                </div>
                {arePoliciesPresent && !policyTos?.privacy && (
                  <Link
                    to="/policy/privacy"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-privacy"
                  >
                    Privacy
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.termsAndConditions && (
                  <Link
                    to="/policy/tnc"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-terms-conditions"
                  >
                    Terms & Conditions
                  </Link>
                )}
                {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                  <Link
                    to="/contactus"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-us"
                  >
                    Contact Us
                  </Link>
                ) : null}
                {features?.features?.customPages ? (
                  <>
                    {getActiveNavbar('About Us', filterBar) && (
                      <Link
                        to="/aboutus"
                        className={quickLinks}
                        font-customisation="para-text"
                        color-customisation="footer-links"
                        id="footer-about-us"
                      >
                        About Us
                      </Link>
                    )}
                    {getActiveNavbar('FAQs', filterBar) && (
                      <Link
                        to="/faqs"
                        className={quickLinks}
                        font-customisation="para-text"
                        color-customisation="footer-links"
                        id="footer-faqs"
                      >
                        FAQs
                      </Link>
                    )}
                  </>
                ) : null}
              </div>
              <div className="mb-50 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Account
                </div>
                <Link
                  to="/track-order"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-track-order"
                >
                  Track Order
                </Link>
                {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                  <Link
                    to="/policy/cancellation"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-cancellation-refund"
                  >
                    Cancellation &amp; Refund
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                  <Link
                    to="/policy/shipping"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-shipping-delivery"
                  >
                    Shipping &amp; Delivery
                  </Link>
                )}
                {/* {customPages?.pages?.results?.map((page) => {
                  if ((getBuyerJWTToken() && page.protected) || !page.protected) {
                    return (
                      <Link key={page.id} to={page.url} className={quickLinks}>
                        {page.name}
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })} */}
              </div>
              <div className="mb-10 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Company
                </div>

                <div className=" text-priceText ">
                  <p
                    className={quickLinks}
                    font-customisation="para-text"
                    id="footer-contact-address"
                    color-customisation="footer-links"
                  >
                    <Image noDefaultDimensions={true} src={Pin} alt="Email" className="mr-14 hidden" />
                    {storeInfo?.contactInfo?.address}
                  </p>
                  {storeInfo?.contactInfo?.number ? (
                    <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                      <Image
                        noDefaultDimensions={true}
                        src={Phone}
                        alt="Phone"
                        className="mr-14 hidden"
                        style={{ filter: 'brightness(0.3)' }}
                      />
                      <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                        {storeInfo?.contactInfo?.number}
                      </a>
                    </p>
                  ) : null}
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Email}
                      alt="Email"
                      className="mr-14 hidden"
                      style={{ filter: 'brightness(0.3)' }}
                    />
                    <a
                      href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                        storeInfo?.contactInfo?.email
                      }`}
                      className="underline"
                    >
                      <p className="" id="footer-contact-mail">
                        {storeInfo?.contactInfo?.email}
                      </p>
                    </a>
                  </p>
                </div>
                <div>
                  <span className="flex mt-0 md:mt-20 justify-center md:justify-start">
                    {storeInfo?.hasSocialLinks ? (
                      <div className="flex items-center justify-start md:justify-center flex-no-wrap">
                        <div className="flex items-center opacity-50">
                          {storeInfo?.social?.facebook ? (
                            <a
                              href={storeInfo?.social?.facebook}
                              title="Facebook"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-20 md:h-20 w-17 h-17"
                                src={Facebook}
                                id="footer-facebook-link"
                              />
                            </a>
                          ) : null}
                          {storeInfo?.social?.twitter ? (
                            <a
                              href={storeInfo?.social?.twitter}
                              title="Twitter"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <TwitterFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-20 md:h-20 w-17 h-17"
                                src={Twitter}
                                id="footer-twitter-link"
                              />
                            </a>
                          ) : null}
                          {storeInfo?.social?.instagram ? (
                            <a
                              href={storeInfo?.social?.instagram}
                              title="Instagram"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <InstagramFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-20 md:h-20 w-17 h-17"
                                id="footer-instagram-link"
                                src={Instagram}
                              />
                            </a>
                          ) : null}
                          {storeInfo?.social?.pinterest ? (
                            <a
                              href={storeInfo?.social?.pinterest}
                              title="Pinterest"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-20 md:h-20 w-17 h-17"
                                src={Pinterest}
                                id="footer-pinterest-link"
                              />
                            </a>
                          ) : null}
                          {storeInfo?.social?.youtube ? (
                            <a
                              href={storeInfo?.social?.youtube}
                              title="YouTube"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-20 md:h-20 w-17 h-17"
                                src={Youtube}
                                id="footer-youtube-link"
                              />
                            </a>
                          ) : null}
                          {storeInfo?.social?.linkedin ? (
                            <a
                              href={storeInfo?.social?.linkedin}
                              title="LinkedIn"
                              target="_black()"
                              className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                            >
                              {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                              <Image
                                noDefaultDimensions={true}
                                className="md:w-24 md:h-16 w-16 h-15"
                                src={Linkedin}
                                id="footer-linkedin-link"
                              />
                            </a>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {/* <InstamojoLogo /> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
